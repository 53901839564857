import './style.scss';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


function isMobile() {
    return window.innerWidth <= 768; // Par exemple, pour les �crans de 768px ou moins
}

// Fonction pour initialiser la carte Leaflet
function initializeMap(block) {
    const mapElement = block.querySelector("#implantation-map");

    if (!mapElement) return;

    // V�rifier si une carte est d�j� initialis�e sur cet �l�ment
    if (block._leafletMap) {
        block._leafletMap.remove(); // Supprimer l'ancienne carte
        block._leafletMap = null;
    }

    var wrapper = mapElement.getAttribute('data-map-points');

    var points = [];
    try {
        points = JSON.parse(wrapper);
    } catch (error) {
        console.error('Erreur lors du parsing des donn�es JSON:', error);
    }

    // Initialisation de la carte Leaflet
    const map = L.map(mapElement).setView([46.603354, 1.888334], 5);

    // Stocker l'instance de la carte sur l'�l�ment du bloc
    block._leafletMap = map;

    // Ajouter des tuiles OpenStreetMap � la carte
    L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://carto.com/attributions">CartoDB</a>',
        subdomains: 'abcd',
        maxZoom: 19
    }).addTo(map);

    // Initialiser les marqueurs pour ce bloc
    block._markers = {};

    // Ajouter les points sur la carte
    points.forEach(function(point) {

       // D�finir les tailles d'ic�nes pour desktop et mobile
        const iconSize = isMobile() ? [20, 20] : [26, 26];
        const iconAnchor = isMobile() ? [10, 10] : [13, 13];
        const popupAnchor = isMobile() ? [0, -10] : [0, -13];

        // Cr�er l'ic�ne personnalis�e
        const customDivIcon = L.divIcon({
            className: `is-filtered icon-wrapper icon-wrapper-${point.type} filtered-${point.type}`,
            html: `<div id="marker-${point.id}" data-target="implantation-${point.id}" class="marker-icon">
                       <i class="icon-${point.type}"></i>
                   </div>`,
            iconSize: iconSize,
            iconAnchor: iconAnchor,
            popupAnchor: popupAnchor
        });

        // Ajouter le marqueur sur la carte
        const marker = L.marker([parseFloat(point.lat), parseFloat(point.lng)], { icon: customDivIcon }).addTo(map);

        // Stocker le marqueur dans l'objet markers du bloc
        block._markers[`marker-${point.id}`] = marker;

        // Cr�er le contenu de la popup
        const popupContent = `
            <div class="icon-wrapper icon-wrapper-${point.type}">
                <i class="icon-${point.type}"></i>
            </div>
            <div id="content">
                <h4 id="firstHeading" class="firstHeading">${point.title}</h4>
                <div id="bodyContent">
                    <p>${point.address}</p>
                </div>
            </div>
        `;

        // Ajouter la popup au marqueur
        marker.bindPopup(popupContent);

        // Ajouter un �v�nement au clic sur le marqueur
        marker.on('click', function() {
            openAccordionAndScroll(point, block);
            map.setView(marker.getLatLng(), 7);
        });
    });
}

// Fonction pour ouvrir l'accord�on et faire d�filer jusqu'� l'�l�ment
function openAccordionAndScroll(point, block) {
    const accordionItem = block.querySelector(`#collapse-${point.region_slug}`);
    if (accordionItem) {
        accordionItem.classList.add('expanded');
    }
}

function handleAccordionClick(event) {
    const accordionHeader = event.target;
    const accordionItem = accordionHeader.parentElement;

    if (accordionItem) {
        // Basculer la classe 'expanded' sur l'�l�ment
        accordionItem.classList.toggle('expanded');
    }
}

// Fonction pour centrer la carte au clic sur l'implantation
function centerMapClickImplantationCard(event, block) {
    const item = event.currentTarget;
    const markerId = item.getAttribute('data-target'); // R�cup�rer l'ID du marqueur
    const marker = block._markers[markerId]; // Utiliser les marqueurs du bloc

    if (marker) {
        marker.fire('click'); // Simuler un clic sur le marqueur
        block._leafletMap.setView(marker.getLatLng(), 10); // Centrer la carte sur le marqueur
    }
}

function handleFilterClick(event, block) {
    const filter = event.currentTarget;
    const type = filter.getAttribute('data-filter');

    // Si le filtre est d�j� actif, tout r�initialiser
    if (filter.classList.contains('active')) {
        block.querySelectorAll('.term-filter').forEach(btn => btn.classList.remove('active'));
        block.querySelectorAll('.is-filtered').forEach(item => item.classList.remove('hidden'));
        return;
    }

    // Activer le filtre cliqu�
    block.querySelectorAll('.term-filter').forEach(btn => btn.classList.remove('active'));
    filter.classList.add('active');

    // Masquer tous les �l�ments et afficher uniquement ceux filtr�s
    block.querySelectorAll('.is-filtered').forEach(item => item.classList.add('hidden'));
    block.querySelectorAll(`.filtered-${type}`).forEach(item => item.classList.remove('hidden'));
}

function initBlocksMap() {
    const BlockMap = document.querySelectorAll('.wp-block-edeis-implantations-map');
    BlockMap.forEach(block => {
        initializeMap(block); // Initialiser la carte pour chaque bloc

        block.querySelectorAll('li[data-target]').forEach(item => {
            item.addEventListener('click', (event) => centerMapClickImplantationCard(event, block));
        });

        block.querySelectorAll('.term-filter').forEach(filter => {
            filter.addEventListener('click', (event) => handleFilterClick(event, block));
        });

        block.querySelectorAll('.accordion-header').forEach(headerAccordeon => {
            headerAccordeon.addEventListener('click', handleAccordionClick);
        });
    });
}

// Fonction de d�clenchement au chargement du DOM
document.addEventListener('DOMContentLoaded', () => {
    initBlocksMap();

    // Gestion pour l'aper�u dans l'�diteur Gutenberg avec ACF
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=edeis/implantations-map', function($block) {
            // $block est pass� en tant qu'objet jQuery, on r�cup�re l'�l�ment DOM natif
            const blockElement = $block[0];
            initializeMap(blockElement);
        });
    }
});
